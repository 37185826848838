<div id="content">
  <header [style.height.vh]="headerVh">
    <div class="header_background_slider">
      <carousel
        [slides]="headerSliderSlides"
        [options]="{controls: false, autoplay: true}"
      ></carousel>
    </div>
    <div class="header_background"></div>
    <div class="header_block">
      <div class="header_block_title">
        <h1 class="header_title">
          МАФ
          <span>Улан-Удэ</span>
        </h1>
        <h2 *ngIf="headerVh !== 30" class="header_subtitle">Художественные изделия из бетона</h2>
      </div>
      <div class="header_block_contacts">
        <p *ngIf="headerVh !== 30">Адрес: Бурятия, г.Улан-Удэ, Сиреневая 29</p>
        <p>Телефон: 8(914)636-33-58 <br> 8(914)631-71-21 <br> 8(902)562-11-08</p>
        <p>Email: mafulanude@mail.ru</p>
      </div>
    </div>
  </header>

  <div class="main">
    <h3 class="flowerpots_title" id="flowerpots">Вазоны</h3>
    <div class="products">
      <div *ngIf="products.length === 0" class="products_spiner">
        <mat-spinner color="accent" diameter="40"></mat-spinner>
      </div>
      <mat-grid-list *ngIf="products.length !== 0" [cols]="productCols" [rowHeight]="productRowHeight">
        <mat-grid-tile *ngFor="let flowerpot of products.flowerpots">
          <div class="product">
            <img src="/assets/images/products/preview/{{flowerpot.image}}" alt="lotos" (click)="openDialog(flowerpot)">
            <span><b>{{flowerpot.name}}</b></span>
            <span>{{flowerpot.size}}</span>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>

    <h3 class="sculptures_title" id="sculptures">Скульптура</h3>
    <div class="products">
      <div *ngIf="products.length === 0" class="products_spiner">
        <mat-spinner color="accent" diameter="40"></mat-spinner>
      </div>
      <mat-grid-list *ngIf="products.length !== 0" [cols]="productCols" [rowHeight]="productRowHeight">
        <mat-grid-tile *ngFor="let sculpture of products.sculptures">
          <div class="product">
            <img src="/assets/images/products/preview/{{sculpture.image}}" alt="lotos" (click)="openDialog(sculpture)">
            <span><b>{{sculpture.name}}</b></span>
            <span>{{sculpture.size}}</span>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>

  </div>
  <footer>
    <span>
      МАФ Улан-Удэ, 2021г. Все права защищены.
    </span>
  </footer>
</div>