import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnInit {
  products: any = [];
  flowerpots: any = [];

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.records.subscribe((data: any) => {
      if (data.length != 0) {
        this.products = data;
        this.flowerpots = this.products.flowerpots.filter(el => el.type === 'flowerpots');
      }
    })
  }

}
