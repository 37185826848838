import { HostListener, Input, Component, OnInit, Inject } from '@angular/core';
import { DataService } from '../services/data.service';
import { ModalImageDialog } from '../shared/modal-image/modal-image-dialog';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  products: any = [];
  public headerSliderSlides: Array<Object> = [
    {
      src: "/assets/images/header_slider/babochka2-2020.jpg",
      position: "50% 70%"
    },
    {
      src: "/assets/images/header_slider/arsalan_img_ver2.jpg",
      position: "50% 18%"
    },
    {
      src: "/assets/images/header_slider/glr_lotos_OEZ_baikal_gavany.jpg",
      position: "50% 44%"
    },
    {
      src: "/assets/images/header_slider/90x90-sber-2020.jpg",
      position: "50% 60%"
    },
    {
      src: "/assets/images/header_slider/lotos_flowers.jpg",
      position: "50% 15%"
    },
    {
      src: "/assets/images/header_slider/lev-2020.jpg",
      position: "50% 30%"
    },
    {
      src: "/assets/images/header_slider/glr_90x90_sberbank.jpg",
      position: "50% 75%"
    },
    {
      src: "/assets/images/header_slider/selenga-ATB-2020.jpg",
      position: "50% 30%"
    },
    {
      src: "/assets/images/header_slider/babochka-2020.jpg",
      position: "50% 60%"
    },
    {
      src: "/assets/images/header_slider/glr_parovoz.jpg",
      position: "50% 30%"
    },
  ]
  productCols = 4
  productRowHeight = '1:1.3'
  headerVh = 70

  constructor(public dialog: MatDialog, private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.records.subscribe((data: any[]) => {
      this.products = data;
    })
    this.productTileResize(window);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.productTileResize(event.target);
  }

  productTileResize(target) {
    if (target.innerWidth > 800) {
      this.productCols = 4;
      this.productRowHeight = '1:1.3';
      this.headerVh = 70;
    } else {
      this.productCols = 2;
      this.productRowHeight = '1:1.2';
      this.headerVh = 50;
    }
  }

  openDialog(product) {
    this.dialog.open(ModalImageDialog, {
      data: product,
      panelClass: 'modal-image-dialog'
    });
  }
}
