import { Component, Input, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from "@angular/animations";

@Component({
  selector: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('1000ms', style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate('1000ms', style({ opacity: 0 }))
      ])
    ])
  ]
})

export class CarouselComponent implements OnInit {
  private _defaultOptions: any = {
    controls: true,
    autoplay: false,
  };

  @Input() slides: Array<Object>;
  @Input() options: any = {
    controls: true,
    autoplay: false,
  };
  autoplayTimer
  currentSlide = 0;

  constructor() {
  }

  ngOnInit(): void {
    this.autoplayTimer = setTimeout(() => this.autoplayStart(), 5000);
  }

  autoplayStart(): any {
    this.onNextClick();
    this.autoplayTimer = setTimeout(() => this.autoplayStart(), 5000);
  }

  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
  }
}
