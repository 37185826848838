import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private REST_API_SERVER = "https://mafulanude.ru/api";
  public records: any = new BehaviorSubject([]);
  // public records: any = [];

  constructor(private httpClient: HttpClient) { }

  public getProducts() {
    const http = this.httpClient.get(`${this.REST_API_SERVER}/products.php`);
    http.subscribe((data: any[]) => {
      this.records.next(data);
    });
    return http;
  }
}
