<div id="content">
  <div class="main">
    <div id="map" style="width: 100%; height: 50%"></div>
    <div class="main-content">
      <div *ngIf="showImage" class="image">
        <img src="/assets/images/lion-arsalan.jpg" alt="Лев Арсалан">
      </div>
      <div class="contacts">
        <p><b>МАФ. Художественные изделия из бетона</b></p> <br>
        <p><b>Адрес</b>: Бурятия, г.Улан-Удэ, Сиреневая 29</p>
        <p><b>Телефон</b>: 8(914)636-33-58, 8(914)631-71-21, 8(902)562-11-08</p>
        <p><b>Электронная почта</b>: mafulanude@mail.ru</p> <br>
        <p><b>Сайт</b>: <a href="https://mafulanude.ru/">mafulanude.ru</a> </p>
      </div>
    </div>
  </div>
  <footer>
    <span>
      МАФ Улан-Удэ, 2021г. Все права защищены.
    </span>
  </footer>
</div>
