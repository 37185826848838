<div class="top-nav">
  <mat-toolbar color="accent" [ngClass]="{'top-nav-t': headerTransparent}">
    <div *ngIf="!expandedToolbar">
      <button mat-icon-button (click)="snav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <h1>
      <a href="#" (click)="scrollToElement('content', $event)">{{title}}</a>
    </h1>
    <div class="toolbar-menu" *ngIf="expandedToolbar">
      <span>
        <a href="#" (click)="scrollToElement('flowerpots', $event)">Вазоны</a>
      </span>
      <span>
        <a href="#" (click)="scrollToElement('sculptures', $event)">Скульптура</a>
      </span>
      <span>
        <a routerLink="/contacts" routerLinkActive="active-link">Контакты</a>
      </span>
      <span>
        <a routerLink="/price" routerLinkActive="active-link">Прайс-Лист</a>
      </span>
    </div>
  </mat-toolbar>
</div>
<mat-sidenav-container>
  <mat-sidenav #snav mode="over" [style.marginTop.px]="45">
    <mat-nav-list>
      <a mat-list-item href="#" (click)="scrollToElement('flowerpots', $event); sidenav.close()">Вазоны</a>
      <a mat-list-item href="#" (click)="scrollToElement('sculptures', $event); sidenav.close()">Скульптура</a>
      <a mat-list-item routerLink="/contacts" (click)="sidenav.close()">Контакты</a>
      <a mat-list-item routerLink="/price" (click)="sidenav.close()">Прайс-Лист</a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content (scroll)="onScroll()">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
