import { HostListener, Component, OnInit } from '@angular/core';

declare global {
  interface Window {
    DG: any;
  }
}

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  showImage = true
  constructor() { }

  ngOnInit(): void {
    this.showHideImage();
    this.loadMapScript();
    this.initMapMarker();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.showHideImage();
  }

  loadMapScript() {
    let scriptUrl = 'https://maps.api.2gis.ru/2.0/loader.js?pkg=full';
    var isFound = false;
    let scripts = document.getElementsByTagName("script")

    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != scriptUrl) continue;
      isFound = true;
      break;
    }

    if (isFound) return;

    let node = document.createElement('script');
    node.src = scriptUrl;
    node.type = 'text/javascript';
    node.async = false;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  initMapMarker() {
    if (!window.DG) {
      // wating for map if it's not ready
      setTimeout(() => { this.initMapMarker() }, 400);
      return;
    }

    window.DG.then(function () {
      const map = window.DG.map('map', {
        center: [51.869436, 107.544438],
        zoom: 15
      });
      window.DG.marker([51.871271, 107.544924]).addTo(map).bindPopup('Малые Архитектурные Формы');
    });
  }

  showHideImage() {
    if (window.innerWidth <= 600) {
      this.showImage = false;
    } else {
      this.showImage = true;
    }
  }
}