import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  image: String;
  name: String;
}

@Component({
  selector: 'modal-image',
  templateUrl: 'modal-image-dialog.html'
})

export class ModalImageDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) { }
}