<!-- carousel -->
<div class="carousel">
  <ng-container *ngFor="let slide of slides; let i = index">
    <img
      *ngIf="i === currentSlide"
      [src]="slide.src"
      class="slide"
      [ngStyle]="{ 'object-position': slide.position || '50% 50%' }"
      @carouselAnimation
    />
  </ng-container>

  <!-- controls -->
  <button *ngIf="options.controls" class="control prev" (click)="onPreviousClick()">
    <span class="arrow left"></span>
  </button>
  <button *ngIf="options.controls" class="control next" (click)="onNextClick()">
    <span class="arrow right"></span>
  </button>
</div>